import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { PHOTO_PATH } from 'constants/paths'
import styles from './PhotoTile.styles'

const useStyles = makeStyles(styles)

function PhotoTile({ name, photoId, photo }) {
  const classes = useStyles()
  const history = useHistory()

  const goToPhoto = () => {
    return history.push(`${PHOTO_PATH}/${photoId}`)
  }

  const getPreviewImage = () => {
    return `https://firebasestorage.googleapis.com/v0/b/wondershot.appspot.com/o/photos%2F${photoId}%2Fpreview1600.jpg?alt=media`
  }

  const getPreviewImageSrcSet = () => {
    let srcsetStr = ''
    const srcsetSizes = [
      { px: 400, x: '0.5x' },
      { px: 800, x: '0.75x' },
      { px: 1600, x: '1.0x' },
      { px: 2000, x: '2.0x' }
    ]
    for (const size of srcsetSizes) {
      srcsetStr += `https://firebasestorage.googleapis.com/v0/b/wondershot.appspot.com/o/photos%2F${photoId}%2Fpreview${size.px}.jpg?alt=media ${size.x},`
    }
    return srcsetStr
  }

  return (
    <Paper className={classes.root} square role="listitem" elevation={16}>
      <img style={{maxHeight: '700px'}}
        src={getPreviewImage()}
        srcSet={getPreviewImageSrcSet()}
        alt={ name + ' - ' + photo.title}
        width="100%"
        onClick={goToPhoto}
        loading="lazy"
      />
    </Paper>
  )
}

PhotoTile.propTypes = {
  photoId: PropTypes.string.isRequired,
  name: PropTypes.string
}

PhotoTile.defaultProps = {
  showDelete: true
}

export default PhotoTile
