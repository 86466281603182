export default (theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  tiles: {
    display: 'flex',
    ...theme.flexColumnCenter,
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  empty: {
    padding: theme.spacing(4)
  }
})
