import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import LoadingSpinner from 'components/LoadingSpinner'
import { PHOTOS_COLLECTION } from 'constants/firebasePaths'
import PhotoTile from '../PhotoTile'
import styles from './PhotosList.styles'

const useStyles = makeStyles(styles)

function useProjectsList() {
  useFirestoreConnect([
    {
      collection: PHOTOS_COLLECTION,
      orderBy: ['created', 'desc']
    }
  ])

  // Get projects from redux state
  const photos = useSelector(({ firestore: { ordered } }) => ordered.photos)

  return { photos: photos }
}

function PhotosList() {
  const classes = useStyles()
  const { photos } = useProjectsList()

  // Show spinner while projects are loading
  if (!isLoaded(photos)) {
    return <LoadingSpinner />
  }

  return (
    <div className={classes.root}>
      <div className={classes.tiles} role="list">
        {!isEmpty(photos) ? (
          photos.map((photo, ind) => {
            return (
              <PhotoTile
                key={photo.id}
                name={photo?.name}
                photoId={photo.id}
                photo={photo}
              />
            )
          })
        ) : (
          <Paper className={classes.empty}>
            <Typography>No Photos Found</Typography>
          </Paper>
        )}
      </div>
    </div>
  )
}

export default PhotosList
